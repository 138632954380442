<template>
<div class="section">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowEditDialog }">
        <div class="modal-background"></div>
        <div class="modal-card scrollable" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Edit Instant Ticket Book</p>&nbsp;&nbsp;
                <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
            </header>
            <section class="modal-card-body has-text-weight-semibold">
                <div class="columns is-mobile">
                    <div class="column is-7">
                        Game:
                    </div>
                    <div class="column  is-5">
                        {{ tempBook.gameNumber }}
                    </div>                    
                </div>
                <div class="columns is-mobile">
                    <div class="column is-7">
                        Game Name:
                    </div>
                    <div class="column is-5">
                        {{ book.gameName != null ? toTitleCase(book.gameName) : null}}
                    </div>                    
                </div>
                <div class="columns is-mobile">
                    <div class="column is-7">
                        Book Number:
                    </div>
                    <div class="column is-5">
                        {{ book.bookNumber }}
                    </div>                    
                </div>
                <!-- <div class="columns is-mobile" v-if="book.status=='ONSALE' && book.location == 'R'"> -->
                <div class="columns is-mobile" v-if="book.status=='ONSALE' || book.status == 'MACHINE' || tempBook.status == 'MACHINE'">
                    <div class="column is-7">
                        Bin #
                    </div>
                    <div class="column is-5">
                        <b-field>
                            <b-select placeholder="Select a Bin"
                                v-model="tempBook.saleRackLoc"
                                >
                                <option value="" key="" v-if="book.location=='M'"></option>
                                <option
                                    v-for="bin in lotteryBins"
                                    :value="bin"                                
                                    :key="bin"
                                    >
                                    {{ bin }}
                                    <!-- <span v-if="book.location=='M'">{{book.location}}</span>{{ bin }} -->
                                </option>
                            </b-select>
                        </b-field>                    
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column is-7">
                        Date Received:
                    </div>
                    <div class="column is-5">
                        {{ book.createdDate | formatDate }}
                    </div>                    
                </div>                 
                <div class="columns is-mobile" v-if="book.activatedDate != null">
                    <div class="column is-7">
                        Date Activated:
                    </div>
                    <div class="column is-5">
                        {{ book.activatedDate | formatDate }}
                    </div>                    
                </div>
                <div class="columns is-mobile" v-if="book.closedDate != null">
                    <div class="column is-7">
                        Date Closed:
                    </div>
                    <div class="column is-5">
                        {{ book.closedDate | formatDate }}
                    </div>                    
                </div>
                <div class="columns is-mobile" v-if="book.status == 'RETURNED'">
                    <div class="column is-7">
                        Date Returned:
                    </div>
                    <div class="column is-5">
                        <!-- {{ book.returnedDate | formatDate }} -->
                        <div v-if="this.overrideAllowed">
                            <span>
                                <b-datepicker 
                                    :mobile-native="isMobileNativeDatePicker"
                                    :min-date="minShiftDate"
                                    :max-date="maxShiftDate"                                        
                                    position="is-bottom-left"
                                    size="is-small"
                                    placeholder="Date..."
                                    required
                                    v-model="tempBook.returnedDateObj">
                                </b-datepicker>                            
                            </span> 
                        </div>
                        <div v-else>
                            {{ book.returnedDate | formatDate }}
                        </div>                        
                    </div>                    
                </div>                                                                                 
                <div class="columns is-mobile">
                    <div class="column is-7">
                        Last Tickets Sold:
                    </div>
                    <div class="column is-5">

                        <b-field v-if="book.status == 'MACHINE'">
                            <b-numberinput placeholder="Seq#" compact
                                :disabled="tempBook.settled"
                                v-model="tempBook.lastTicketSold"
                                class="lottery-seq-input"
                                size="is-small"
                                :min="book.ticketStartNum"
                                :max="book.ticketCount"
                                :controls="showNumberControls"
                                controls-alignment="center"
                                controls-position="compact"
                                controls-rounded
                                @input="bookSoldTicketChanged"
                                @focus="$event.target.select()"
                                @wheel.native="$event.target.blur()"
                                icon="account">
                            </b-numberinput>
                        </b-field>
                        <span v-else>
                            {{ (book.lastTicketSold > 0) ? book.lastTicketSold : 0 }}
                        </span>

                    </div>                    
                </div>
                <div class="columns is-mobile">
                    <div class="column is-7">
                        Inventory Ticket Count:
                    </div>
                    <div class="column is-5">

                        <b-field v-if="book.status == 'MACHINE'">
                            <b-numberinput placeholder="Seq#" compact
                                :disabled="tempBook.settled"
                                v-model="tempBook.inventoryCount"
                                class="lottery-seq-input"
                                size="is-small"
                                :min="book.ticketStartNum"
                                :max="book.ticketCount"
                                :controls="showNumberControls"
                                controls-alignment="center"
                                controls-position="compact"
                                controls-rounded
                                @input="bookInvCountChanged" 
                                @focus="$event.target.select()"
                                @wheel.native="$event.target.blur()" 
                                >
                            </b-numberinput>
                        </b-field>
                        <span v-else>
                            {{ (tempBook.inventoryCount > 0) ? tempBook.inventoryCount : 0 }}
                        </span>

                    </div>                    
                </div>                
               
                <div class="columns is-mobile">
                    <div class="column is-7">
                        Book Status:
                        <a href="#" @click="unlockClicked" v-if="!overrideAllowed && tempBook.status != 'SETTLED' && (book.status == 'CLOSED' || book.status == 'RETURNED')"> 
                            <b-icon
                                pack="fas"
                                type="is-danger"
                                icon="unlock"
                                title="Unlock"
                                size="is-small"
                                >
                            </b-icon>
                        </a>
                    </div>
                    <div class="column is-5">
                        <div class="block">
                            <b-radio v-model="tempBook.status"
                                :disabled="tempBook.settled"
                                name="RECEIVED"
                                native-value="RECEIVED"
                                @input="processStatusChange"
                                v-if="book.status == 'ONSALE' || book.status == 'MACHINE' || book.status == 'RECEIVED' || overrideAllowed ">
                                In Safe
                            </b-radio>
                            <b-radio v-model="tempBook.status"
                                :disabled="tempBook.settled"
                                name="MACHINE"
                                native-value="MACHINE"
                                @input="processStatusChange"
                                v-if="(book.location == 'M' && book.status == 'MACHINE') || book.status == 'RECEIVED'">
                                Outside Sale
                            </b-radio>
                            <b-radio v-model="tempBook.status"
                                :disabled="tempBook.settled"
                                name="ONSALE"
                                native-value="ONSALE"
                                @input="processStatusChange"
                                v-if="(book.location == 'R' && book.status == 'ONSALE')">
                                Inside Sale
                            </b-radio>                            
                            <b-radio v-model="tempBook.status"
                                :disabled="tempBook.settled"
                                name="CLOSED"
                                native-value="CLOSED"
                                @input="processStatusChange"
                                v-if="(book.location == 'M' && book.status == 'MACHINE') || book.status == 'CLOSED'">
                                Closed
                            </b-radio>                            
                            <b-radio v-model="tempBook.status"
                                :disabled="tempBook.settled"
                                name="RETURNED"
                                native-value="RETURNED"
                                @input="processStatusChange"
                                v-if="book.status == 'RECEIVED' || book.status == 'RETURNED'">
                                Returned
                            </b-radio>                            
                            <b-radio v-model="tempBook.status"
                                :disabled="tempBook.settled"
                                name="SETTLED"
                                native-value="SETTLED"
                                @input="processStatusChange"
                                v-if="tempBook.status == 'SETTLED'">
                                Settled
                            </b-radio>                             
                        </div>
                    </div>                    
                </div> 
                <div class="columns is-mobile" v-if="book.status == 'CLOSED' || book.status == 'RETURNED'">
                    <div class="column is-7">
                        Book Settled:
                    </div>
                    <div class="column is-5">
                        <b-field>
                            <b-checkbox
                                :disabled="book.status == 'SETTLED'"
                                @input="settleBook"
                                v-model="tempBook.settled">
                                <span v-if="tempBook.settled">
                                    <b-datepicker 
                                        :mobile-native="isMobileNativeDatePicker"
                                        :min-date="minShiftDate"
                                        :max-date="maxShiftDate"                                        
                                        position="is-top-left"
                                        size="is-small"
                                        placeholder="Date..."
                                        required
                                        v-model="tempBook.bookSettlementDate">
                                    </b-datepicker>                            
                                </span>                                                                  
                            </b-checkbox>                            
                        </b-field> 
                    </div>

                </div>
            
            </section>
            <footer class="modal-card-foot">
            <button class="button is-success" 
                    type="button" 
                    @click="submitForm" 
                    :disabled='isSaveDisabled'>Save</button>
            <button class="button" type="button" @click="closeDialog">Cancel</button>
            <span class='is-danger'></span>
            </footer>

        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        isShowEditDialog: Boolean,
        book: {},
        bookSettled: Boolean,
        tempBook: {},
        lotteryBins: [],
    },

    data() {
        return {
            isPrinting: false,
            showNumberControls: true,
            isMobileNativeDatePicker: false,
            overrideAllowed: false,
            maxShiftDate: new Date(),
            minShiftDate: new Date(Date.now() - ( 3600 * 1000 * 24 * 60)),

        }
    },

    methods: {
        closeDialog() {

            this.$buefy.dialog.confirm({
                title: 'Leaving without saving!',
                message: 'Are you sure you want to discard all changes and leave?',
                confirmText: 'Discard Changes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.$emit('hide-dialog')
                }
            })            
            
        },

        processStatusChange() {
            if (this.tempBook.status == 'CLOSED') {
                this.tempBook.lastTicketSold = this.tempBook.ticketCount                
            } else {
                this.tempBook.lastTicketSold = this.book.lastTicketSold
            }
            this.bookSoldTicketChanged()
            
        },

        settleBook() {
            if (this.tempBook.settled) {
                this.tempBook.status = 'SETTLED'
                if (this.tempBook.bookSettlementDate == null) {
                    this.tempBook.bookSettlementDate = new Date()
                }                
            } else {
                this.tempBook.status = this.book.status
            }
        },

        submitForm() {
            this.overrideAllowed = false
            this.$emit('save-record')
            this.$emit('hide-dialog')
        },

        recalcTickets() {
            this.$emit('recalculate-ticket-record')
        },

        bookSoldTicketChanged() {
            this.tempBook.inventoryCount = this.tempBook.ticketCount - this.tempBook.lastTicketSold
            this.tempBook.inventoryValue = this.tempBook.inventoryCount * this.tempBook.pricePoint
            
        },        

        bookInvCountChanged() {
            this.tempBook.lastTicketSold = this.tempBook.ticketCount - this.tempBook.inventoryCount
            this.tempBook.inventoryValue = this.tempBook.inventoryCount * this.tempBook.pricePoint
            
        },

        unlockClicked() {
            this.$buefy.dialog.prompt({
                message: `Enter Password?`,
                inputAttrs: {
                    placeholder: 'e.g. Walter',
                    type: 'password',
                    maxlength: 10
                },
                trapFocus: true,
                onConfirm: (value) => {
                    if (value == this.getSetting('ownerOverridePassword')) {
                        this.overrideAllowed = true
                        this.$buefy.toast.open({
                            message: "Unlocked Cigarette Counts",
                            type: 'is-success'
                        })
                    } else {
                        this.overrideAllowed = false
                        this.$buefy.toast.open({
                            message: "Incorrect Password",
                            type: 'is-danger'
                        })
                    }
                }

            })
        },

        isDateChangeAllowed(date) {
            var today = new Date()
            var otherDay = new Date(date)
            var timeDifference = Math.abs(otherDay.getTime() - today.getTime());

            var days = Math.ceil(timeDifference / (1000 * 3600 * 24))
            if (days > 3) {
                return false
            }
            return true
        }

    },

    computed: {
        isSaveDisabled() {
            if (this.tempBook.settled == true && this.tempBook.bookSettlementDate == null) {
                return true
            } 
            return false
        },
    }

 
}
</script>

<style scoped>
/* .lottery-seq-input input[type=number]  {

    background-color: white;
    text-align: right;
    font-weight: bold;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 90px;
    border-color: gold;
    border-width: 2px;
}
.lottery-seq-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    border-color: gray;
    border-width: 1px;
} */

</style>