<template>
<div class="panel">

    <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">

            <!-- <div class="level-left has-text-centered" @click="showLotteryTable=!showLotteryTable">

            </div> -->
            <div class="level-right has-text-left" v-show="getDivWidth('ReportContent') < 1300">
                <div class="button is-primary has-text-white is-small"  @click="doAction('add')" v-show="fetchStatus =='RECEIVED'">
                    <i class="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;New</div>&nbsp;&nbsp;
            </div>
            <div class="level-right has-text-left" v-show="getDivWidth('ReportContent') < 1300">
                <b-button  title="Print" type="is-dark"
                    v-if="fetchStatus!='SUMMARY'" 
                    outlined 
                    class="is-small is-hidden-mobile" 
                    @click="togglePDFDialog">
                    <i class="fas fa-print"></i>
                </b-button>
            </div>
            <div class="column is-size-6 has-text-centered"> 
                INSTANT &nbsp;&nbsp;LOTTERY
            </div>
            <div class="level-right has-text-right" v-show="getDivWidth('ReportContent') >= 1300">
                <div class="button is-primary has-text-white is-small"  @click="doAction('add')" v-show="fetchStatus =='RECEIVED'">
                    <i class="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;New</div>&nbsp;&nbsp;
            </div>
            <div class="level-right has-text-right" v-show="getDivWidth('ReportContent') >= 1300">
                <b-button  title="Print" type="is-dark"
                    v-if="fetchStatus!='SUMMARY'" 
                    outlined 
                    class="is-small is-hidden-mobile" 
                    @click="togglePDFDialog">
                    <i class="fas fa-print"></i>
                </b-button>
            </div>                                 
        </div>           
    </div>
    <br>
    <div  id="ReportContent">
        <div class="has-background-warning-light ml-5 mr-5 p-5" v-if="!isPrinting">
            <div class="columns">
                    <div class="column">
                        <!-- :disabled="fetchStatus=='RECEIVED'" -->
                        <b-switch                            
                            true-value="RECEIVED"
                            false-value="RECEIVED"
                            size="is-small"
                            v-model="fetchStatus"
                            @input="fetchRecords" 
                            type="is-success">
                                In Safe
                        </b-switch>                       

                        <b-switch 
                            true-value="ONSALE"
                            false-value="RECEIVED"
                            size="is-small"
                            v-model="fetchStatus"
                            @input="fetchRecords"
                            type="is-success">
                                Inside Sale
                        </b-switch>
                   
                        <b-switch 
                            true-value="MACHINE"
                            false-value="RECEIVED"
                            size="is-small"
                            v-model="fetchStatus"
                            @input="fetchRecords"
                            type="is-success">
                                Outside Sale
                        </b-switch>
                        
                        <b-switch 
                            true-value="CLOSED"
                            false-value="RECEIVED"
                            size="is-small"
                            v-model="fetchStatus"
                            @input="fetchRecords"
                            type="is-success">
                                Closed
                        </b-switch>
                                                                        
                        <b-switch 
                            true-value="RETURNED"
                            false-value="RECEIVED"
                            size="is-small"
                            v-model="fetchStatus"
                            @input="fetchRecords"
                            type="is-success">
                                Returned
                        </b-switch>
                        
                        <b-switch 
                            true-value="SETTLED"
                            false-value="RECEIVED"
                            size="is-small"
                            v-model="fetchStatus"
                            @input="fetchRecords"
                            type="is-success">
                                Settled
                        </b-switch>

                        <!-- <b-switch 
                            true-value="SUMMARY"
                            false-value="RECEIVED"
                            size="is-small"
                            v-model="fetchStatus"
                            @input="fetchRecords"
                            type="is-success">
                                Current Summary
                        </b-switch> -->
                    </div> 

                    <b-switch 
                        v-if="fetchStatus!='SUMMARY'"
                        size="is-small"
                        v-model="isShowBarcode"
                        type="is-success">
                            Show Barcode
                    </b-switch> 
                </div>

                <div class="columns"  v-if="fetchStatus == 'RECEIVED' && !isPrinting">
                    <div class="column">
  
                        <b-radio size="is-small" v-model="soldTickets" native-value="all">
                            All Books
                        </b-radio>

                        <b-radio size="is-small" v-model="soldTickets" native-value="partial">
                            Partial Sold Books
                        </b-radio>

                        <b-radio size="is-small" v-model="soldTickets" native-value="full">
                            Full Books
                        </b-radio>

                        <b-radio size="is-small" v-model="soldTickets" native-value="scanDate">
                            Scanned Date (all status)
                        </b-radio>                                              
                    </div>
                   
                </div>

                <div class="columns" v-if="(fetchStatus == 'RETURNED' || fetchStatus == 'CLOSED' || fetchStatus == 'SETTLED' || (fetchStatus == 'RECEIVED' && soldTickets=='scanDate') || fetchStatus == 'SUMMARY')  && !isPrinting">
                    <div class="column is-2">
                        <!-- <b-field label="Date Range" size="is-small" >
                        <b-datepicker
                            size="is-small" 
                            :disabled="showAllBooks"
                            :mobile-native="isMobileNativeDatePicker"  
                            placeholder="Click to select..."
                            v-model="closeDateRange"
                            width="100"                        
                            range>
                        </b-datepicker> 
                        &nbsp;&nbsp;
                        
                        </b-field> -->
                        <b-field>
                            <date-range-selector
                                :disabled="showAllBooks"
                                :dateRange="closeDateRange"
                                :showDateRangeTab="true"
                                @set-date-range="setDateRange"
                                >
                            </date-range-selector>                    
                        </b-field>
                    </div>
                    <div class="column is-2" v-if="soldTickets !='scanDate' && fetchStatus != 'SUMMARY'">
                        <br>
                        <b-switch 
                            size="is-small"
                            @input="fetchRecords" 
                            v-model="showAllBooks" 
                            native-value="all"
                            v-if="(fetchStatus != 'SETTLED')"
                            >
                            Show all {{ fetchStatus.toLowerCase() }} books 
                        </b-switch>
                    </div>
                </div>
        </div>
        <br>
        <div class="panel-heading has-text-weight-bold" v-if="fetchStatus!='SUMMARY'">
            <div class="columns">
                <div class="column has-text-centered is-2">          
                    <div class="is-size-7">
                        <p class="is-size-7">Ticket Sale Direction</p>
                        <p class="is-size-6"> {{ lotterySaleDirection }}</p>
                    </div>
                </div>

                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7">Instant Book Count</p>
                        <p class="is-size-6"> {{ filteredRecords.length }}</p>
                    </div>
                </div>               
                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7">Total Value</p>
                        <p class="is-size-6"> {{ totalInstantValue | formatCurrency }}</p>
                    </div>
                </div>
                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7" v-if="fetchStatus != 'SETTLED'">Total Sold Value</p>
                        <p class="is-size-7" v-if="fetchStatus == 'SETTLED'">Total Settled Value</p>
                        <p class="is-size-6"> {{ totalSoldValue | formatCurrency }}</p>
                    </div>
                </div>
                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7" v-if="fetchStatus != 'RETURNED'">Total Inventory Value</p>
                        <p class="is-size-7" v-if="fetchStatus == 'RETURNED'">Total Returned Value</p>
                        <p class="is-size-6"> {{ totalInventoryValue | formatCurrency }}</p>
                    </div>
                </div>


            </div>
        </div>    
        <div class="is-fullwidth" v-if="fetchStatus!='SUMMARY'">
            <br>
            <b-table class="control has-text-weight-semibold"
                    :data='filteredRecords'
                    :paginated="isPaginated"
                    :per-page="perPage"
                    paginationPosition="both"
                    sortIcon="arrow-up"
                    sortIconSize="is-small"
                    default-sort=gameNumber
                    defaultSortDirection='asc'
                    >

                    <template>

                    <b-table-column class="is-2 has-text-right" field="Edit" label="Edit" v-slot="props" :visible="fetchStatus != 'SETTLED'">
                        <b-button class="is-primary is-small"  v-on:click="doAction('edit',props.row)" outlined rounded><i class="far fa-edit"></i></b-button>
                    </b-table-column> 

                    <b-table-column class="is-2 has-text-right" field="Details" label="Details" v-slot="props">
                        <b-button class="is-primary is-small" v-on:click="showSalesDialog(props.row)" outlined rounded>$</b-button>
                    </b-table-column> 

                    <b-table-column 
                        class="is-2" 
                        field="gameNumber" 
                        label="Game" 
                        v-slot="props" 
                        :searchable="!isPrinting" 
                        sortable 
                        sorticon
                        width="100">
                        <tool-tip>
                            <div slot="content" class="help-tooltip-content">
                                <span class="is-capitalized">{{props.row.gameName.toLowerCase() }}</span>
                            </div>
                            {{ props.row.gameNumber }}
                        </tool-tip>
                        
                    </b-table-column>

                    <b-table-column 
                        class="is-2" 
                        field="bookNumber" 
                        label="Book" 
                        v-slot="props" 
                        :searchable="!isPrinting" 
                        sortable 
                        sorticon
                        width="120">
                        {{ props.row.bookNumber }}
                    </b-table-column>

                    <b-table-column class="is-2" field="barCode"  v-slot="props" label="Barcode" v-if="isShowBarcode">
                        <vue-barcode 
                            v-bind:value="props.row.gameNumber+props.row.bookNumber+(props.row.lastTicketSold ? String(props.row.lastTicketSold).padStart(3, '0')  : '000')" 
                            height="20" 
                            width="1" 
                            displayValue="false">
                            N/A
                        </vue-barcode>
                    </b-table-column>

                    <b-table-column class="is-2" field="gameName" label="Game Name" v-slot="props" :searchable="!isPrinting" sortable sorticon  v-if="getDivWidth('ReportContent') >= 1300">
                    <span class="is-capitalized">{{props.row.gameName.toLowerCase() }}</span>
                    </b-table-column>
                                    
                    <b-table-column class="is-2 has-text-centered" field="tickets" label="Sequence" v-slot="props" :visible="fetchStatus != 'ONSALE' && fetchStatus != 'MACHINE'">
                    {{ props.row.ticketStartNum }} -  {{ props.row.ticketEndNum }}
                    </b-table-column>

                    <!-- <b-table-column 
                        class="is-2 has-text-centered" 
                        field="saleRackLoc" label="Bin" 
                        v-slot="props" 
                        :searchable="!isPrinting" 
                        sortable 
                        sorticon 
                        :visible="fetchStatus == 'ONSALE' || fetchStatus == 'MACHINE'"
                        width="100">
                            <span v-if="props.row.location=='M'">{{props.row.location}}</span>{{ props.row.saleRackLoc }}
                    </b-table-column> -->

                    <b-table-column 
                        class="is-2 has-text-centered" 
                        field="saleRackLoc" label="Bin" 
                        v-slot="props" 
                        :searchable="!isPrinting" 
                        sortable 
                        sorticon 
                        width="100">
                        <!-- <span v-if="props.row.location=='M' && props.row.saleRackLoc">{{props.row.location}}</span> -->
                        {{ props.row.saleRackLoc }}
                    </b-table-column>
                    <!-- :visible="fetchStatus == 'ONSALE' || fetchStatus == 'MACHINE'" -->

                    <b-table-column numeric class="is-2 has-text-right" field="ticketCount" label="Tickets" v-slot="props" sortable sorticon>
                    {{ props.row.ticketCount }}
                    </b-table-column>

                    <b-table-column numeric class="is-2 has-text-right" field="pricePoint" label="Price" v-slot="props" sortable sorticon>
                    {{ props.row.pricePoint | formatCurrency }}
                    </b-table-column>

                    <b-table-column numeric class="is-2 has-text-right" field="ticketCount" label="Value" v-slot="props" sortable sorticon>
                    {{ props.row.ticketCount * props.row.pricePoint  | formatCurrency}}
                    </b-table-column>

                    <b-table-column numeric class="is-2 has-text-right" field="lastTicketSold" label="Sold" v-slot="props" sortable sorticon>
                    <!-- <span v-if="props.row.lastTicketSold">{{ (props.row.lastTicketSold == props.row.ticketCount ? props.row.lastTicketSold : (props.row.lastTicketSold == 0 ? props.row.lastTicketSold : props.row.lastTicketSold + 1 ))}}</span> -->

                    <!-- <span v-else>0</span> -->
                    {{  props.row.lastTicketSold }}
                    </b-table-column>

                    <b-table-column numeric class="is-2 has-text-right" field="ticketCount" label="Inventory" v-slot="props" sortable sorticon>
                    <span>{{ (props.row.lastTicketSold == props.row.ticketCount ? 0 : (props.row.lastTicketSold ? props.row.ticketCount - props.row.lastTicketSold  : props.row.ticketCount  ))}}</span>
                    </b-table-column>

                    <b-table-column numeric class="is-2 has-text-right" field="createdDate" label="Received On" v-slot="props" :visible="fetchStatus == 'RECEIVED'" sortable sorticon>
                    {{ props.row.createdDate.substr(0,10) | formatDate }}
                    </b-table-column>

                    <b-table-column numeric class="is-2 has-text-right" field="closedDate" label="Activated On" v-slot="props" :visible="fetchStatus == 'ONSALE' || fetchStatus == 'MACHINE'" sortable sorticon>
                    {{ props.row.activatedDate | formatDate }}
                    </b-table-column>

                    <b-table-column numeric class="is-2 has-text-right" field="closedDate" label="Closed On" v-slot="props" :visible="fetchStatus == 'CLOSED'" sortable sorticon>
                    {{ props.row.closedDate | formatDate }}
                    </b-table-column>

                    <b-table-column numeric class="is-2 has-text-right" field="returnedDate" label="Returned On" v-slot="props" :visible="fetchStatus == 'RETURNED'" sortable sorticon>
                    <!-- {{ props.row.returnedDate[1]  }}/{{ props.row.returnedDate[2]  }}/{{ props.row.returnedDate[0]  }} -->
                    {{ props.row.returnedDate | formatDate }}
                    </b-table-column>

                    <b-table-column numeric class="is-2 has-text-right" field="bookSettlementDate" label="Settled On" v-slot="props" :visible="fetchStatus == 'SETTLED'" sortable sorticon>
                    {{ props.row.bookSettlementDate | formatDate }}
                    </b-table-column>

                    <b-table-column class="is-2" field="gameNumber" label="Status" v-slot="props" sortable sorticon>
                    {{ ticketStatus(props.row) }} 
                    </b-table-column>               

                    </template>
                    <template slot="empty">
                        <data-loading 
                            :isLoading="isLoading" 
                            :isFullPage="isFullPage"> 
                        </data-loading>
                    </template>
        
            </b-table>

        </div>

        <div class="is-fullwidth">
            <div class="box" v-if="fetchStatus=='SUMMARY'">
                <div class="columns">                
                    <div class="column box is-4">
                        <div class="columns veecli-table">
                            <div class="column is-12 veecli-table-heading">
                                Inventory Summary
                            </div>
                        </div>
                        <br>
                        <div class="columns veecli-table">
                            
                            <div class="column is-8">
                                Opening Balance
                                &nbsp;
                                <tool-tip>
                                    <div slot="title" class="help-tooltip-header">
                                    Opening Balance
                                    </div>
                                    <div slot="content" class="help-tooltip-content">
                                        Amount must be entered in the month end page
                                    </div>
                                    <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                </tool-tip>
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ auditRecord.lastMonthEndInstantLotteryInventory |formatCurrency }}
                            </div>
                        </div>
                        <div class="columns veecli-table">
                            <div class="column is-8">
                                Total Purchase
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ auditRecord.booksReceivedTotalValueBetweenAudits |formatCurrency  }}
                            </div>
                        </div>
                        <div class="columns veecli-table">
                            <div class="column is-8">
                                Total Returns
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ auditRecord.returnedTicketsTotalValueBetweenAudits |formatCurrency  }}
                            </div>
                        </div>
                        <div class="columns veecli-table">
                            <div class="column is-8">
                                Total Inside Sales
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ auditRecord.insideTotalValueBetweenAudits |formatCurrency  }}
                            </div>
                        </div>
                        <div class="columns veecli-table">
                            <div class="column is-8">
                                Total Outside Sales
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ auditRecord.outsideTotalValueBetweenAudits |formatCurrency  }}
                            </div>
                        </div>
                        <div class="columns veecli-table-subhead">
                            <div class="column is-8">
                                Net Balance
                            </div>
                            <div class="column is-4 has-text-right">
                                {{ auditRecord.newBalance |formatCurrency  }}
                            </div>
                        </div>
                    </div>
                    &nbsp;&nbsp;
                    <div class="column box is-8" v-if="invoicePanel">
                        <div class="columns veecli-table">
                            <div class="column is-12 veecli-table-heading">
                                Inventory Receipts
                            </div>
                        </div>
                        <div class="columns veecli-receipt-table">
                            <div class="column is-12">
                                <b-table
                                    :data="currentSummary.purchaseInvoices">
                                    <b-table-column class="is-2 has-text-right" field="date" label="Invoice Date" v-slot="props">
                                        {{ props.row.date | formatDate }}
                                    </b-table-column>
                                    <b-table-column numeric class="is-2 has-text-right" field="number" label="Invoice Number" v-slot="props">
                                        {{ props.row.number }}
                                    </b-table-column>
                                    <b-table-column numeric class="is-2 has-text-right" field="bookCount" label="Book Count" v-slot="props">
                                        {{ props.row.bookCount }}
                                    </b-table-column>
                                    <b-table-column numeric class="is-2 has-text-right" field="amount" label="Invoice Amount" v-slot="props">
                                        {{ props.row.amount  | formatCurrency }}
                                    </b-table-column>
                                    <b-table-column numeric class="is-2 has-text-right" field="scannedCount" label="Scanned Cound" v-slot="props">
                                        {{ props.row.scannedCount }}
                                    </b-table-column>
                                    <b-table-column numeric class="is-2 has-text-right" field="scannedAmount" label="Scanned Amount" v-slot="props">
                                        {{ props.row.scannedAmount | formatCurrency}}
                                    </b-table-column>

                                </b-table>
                            </div>
                        </div>
                    </div>
                    &nbsp;&nbsp;
                </div>
                <br>
            </div>
        </div>

        <div class="panel-heading has-text-weight-bold"  v-if="fetchStatus!='SUMMARY'">
            <div class="columns">
                <div class="column has-text-centered is-2">          
                    <div class="is-size-7">
                        <p class="is-size-7">Ticket Sale Direction</p>
                        <p class="is-size-6"> {{ lotterySaleDirection }}</p>
                    </div>
                </div>

                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7">Instant Book Count</p>
                        <p class="is-size-6"> {{ filteredRecords.length }}</p>
                    </div>
                </div>               
                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7">Total Value</p>
                        <p class="is-size-6"> {{ totalInstantValue | formatCurrency }}</p>
                    </div>
                </div>
                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7" v-if="fetchStatus != 'SETTLED'">Total Sold Value</p>
                        <p class="is-size-7" v-if="fetchStatus == 'SETTLED'">Total Settled Value</p>
                        <p class="is-size-6"> {{ totalSoldValue | formatCurrency }}</p>
                    </div>
                </div>
                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7" v-if="fetchStatus != 'RETURNED'">Total Inventory Value</p>
                        <p class="is-size-7" v-if="fetchStatus == 'RETURNED'">Total Returned Value</p>
                        <p class="is-size-6"> {{ totalInventoryValue | formatCurrency }}</p>
                    </div>
                </div>


            </div>
        </div>
    </div>
                <lottery-book-form
                :storeId="storeId"
                :newTickets="newTickets"
                :action="action"
                :isShowDialog="isShowDialog" 
                :invoice="invoice"
                @hide-dialog="toggleDialog"
                @save-record="saveRecord">
                @recalculate-ticket-record="recalculateTicketRecord"
            </lottery-book-form>

            <lottery-edit-form
                :storeId="storeId"
                :book="book"
                :tempBook="tempBook"
                :lotteryBins="lotteryBins"
                :action="action"
                :isShowEditDialog="isShowEditDialog"  
                @hide-dialog="cancelEditRecord"
                @settle-book="settleBook"
                @save-record="saveEditRecord">
            </lottery-edit-form>

            <lottery-sales-dialog
                ref="booksales"
                :isShowSalesDialog="isShowSalesDialog"
                :book="salesBook"
                @hide-dialog="cancelSalesDialog"
                >

            </lottery-sales-dialog>

            <lottery-PDF-report
                ref="pdfReport"
                :isShowPDFDialog="isShowPDFDialog"
                :fetchStatus="fetchStatus"
                :filteredRecords="filteredRecords"
                :dateRange="closeDateRange"
                :isShowBarcode="isShowBarcode"
                @close-pdf-dialog="togglePDFDialog"
            >

            </lottery-PDF-report>
</div>
</template>

<script>
import UserStore from "../../../../store/UserStore"

import DataLoading from '../../../../components/app/DataLoading'
import ToolTip from '../../../../components/app/ToolTip'
import DateRangeSelector from "../../../../components/app/DateRangeSelector"
import LotteryBookForm from './LotteryBookForm'
import LotteryEditForm from './LotteryEditForm'
import LotteryPDFReport from './LotteryPDFReport'
import LotterySalesDialog from './sales/LotterySalesDialog'


// import jspdf from "jspdf"
import axios from 'axios'
import moment from 'moment'
import VueBarcode from 'vue-barcode'

export default {

    components: {
        DataLoading,
        ToolTip,
        DateRangeSelector,
        VueBarcode,
        LotteryBookForm,
        LotteryEditForm,
        LotteryPDFReport,
        LotterySalesDialog
    },

    metaInfo: {
        title: 'Lottery Inventory Details'
    },

    data() {
        return {
            newTickets: [],
            fetchStatus: "RECEIVED",
            currentSummary: {
                "openingBalance": 1,
                "purchasedAmount": 2,
                "returnedAmount": 3,
                "insideSaleAmount": 4,
                "outsideSaleAmount": 5,
                "purchaseInvoices": [
                    {
                        "date": new Date(),
                        "number": "10023",
                        "bookCount": 0,
                        "amount": 0.0,
                        "scannedAmount": 0.0,
                        "scannedCount": 0.0
                    }
                ]
            },
            user: [],
            records: [],
            invoicePanel: false,
            expense: {},
            expenseTemp: {},
            auditRecord: {},
            isPaginated: true,
            showAllBooks: false,
            perPage: 30,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            isShowPDFDialog: false,
            isShowEditDialog: false,
            isShowBarcode: false,
            isMobileNativeDatePicker: false,
            action: '',
            storeId: 0,
            storeState: UserStore.getters.getStoreState,
            role: '',
            deleteAllowed: true,
            closeDateRange: [new Date(), new Date()],
            book: {},
            isShowSalesDialog: false,
            salesBook: null,
            tempBook: {},
            lotteryBins: [],
            lotterySaleDirection: (this.getSetting('lotteryInsideSaleDirection') == 1 ? "Last to Zero" : "Zero to Last"),
            isPrinting: false,
            soldTickets: 'all',
            barcodeValue: '7457-0010217-089',
            invoice: {
                "id": null,
                "number": "",
                "amount": 0,
                "bookCount": 0,
                "date": new Date(),
                "scannedAmount": 0,
                "scannedCount": 0,
                "storeId": this.getUser().activeStore.id ,
                "userId": this.getUser().id,
                "lotteryBooks": []
            }
        }
    },

    methods: {

        async fetchRecords() {
            if (this.user.activeStore) {
                this.records = []
                this.lotteryBins = this.binsArray()
                if (this.fetchStatus == 'SUMMARY') {
                    this.isLoading = true
                    await this.fetchSummary()
                    return
                }
                if (this.fetchStatus != "RECEIVED") {
                    this.soldTickets = "all"                            
                }
                this.isLoading = true
                var startDate = this.closeDateRange[0].toISOString().split('T')[0]
                var endDate = this.closeDateRange[1].toISOString().split('T')[0]                        

                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/lottery/books"
                    // if (this.fetchStatus != "ALL") {
                    //     url = url + "?status=" + this.fetchStatus
                    // }
                    if (this.fetchStatus == "ONSALE" || this.fetchStatus == "MACHINE") {
                        if (this.fetchStatus == "MACHINE") {
                            url = url + "?status=" + "ONSALE&location=M"
                        } else {
                            url = url + "?status=" + "ONSALE&location=R"
                        } 
                    } else {
                        if (this.fetchStatus == 'RECEIVED' && this.soldTickets == 'scanDate') {
                            url = url + "?created_at_start=" + startDate + "&created_at_end=" + endDate
                        } else {
                            url = url + "?status=" + this.fetchStatus
                        }
                    }
                    if (this.fetchStatus == 'CLOSED' && ! this.showAllBooks) {
                        url = url + "&closed_date_start=" + startDate + "&closed_date_end=" + endDate
                    }
                    if (this.fetchStatus == 'RETURNED' && ! this.showAllBooks) {
                        url = url + "&returned_date_start=" + startDate + "&returned_date_end=" + endDate
                    }
                    if (this.fetchStatus == 'SETTLED') {
                        url = url + "&settlement_date_start=" + startDate + "&settlement_date_end=" + endDate
                    }
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data
                        this.addBookSettledField()
                        this.addInventoryCountAndValue()
                        this.addBookReturnedField()
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        this.records = []
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        async fetchSummary() {
            await this.fetchAuditRecord()
        },

        async fetchAuditRecord() {
        
            this.auditDateString = moment(this.closeDateRange[1]).format('YYYY-MM-DD')
            var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' +
                    UserStore.getters.getActiveStore.storeId + 
                "/lottery/spotcheckaudit/" + this.auditDateString

            var firstDayOfMonth = moment(this.closeDateRange[0]).format('YYYY-MM-DD')
            url = url + "?startingDate="+firstDayOfMonth

            await axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                    this.auditRecord = response.data.data
                    this.auditRecord.newBalance = this.auditRecord.lastMonthEndInstantLotteryInventory +
                        this.auditRecord.booksReceivedTotalValueBetweenAudits -
                        this.auditRecord.returnedTicketsTotalValueBetweenAudits -
                        this.auditRecord.insideTotalValueBetweenAudits -
                        this.auditRecord.outsideTotalValueBetweenAudits                                                    
                    this.isLoading = false
                })
                .catch( (err) => {
                    this.records = []
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.isLoading = false
                    if (err.response.status === 403) {
                        alert('Session Expired. Please log in again!')
                        UserStore.commit('userLogout')
                    }
                })
            return
        },

        capitalize(text) {
            return text.charAt(0).toUpperCase() + text.slice(1)
        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        toggleDialog() {
            this.invoice = {
                "id": null,
                "number": "",
                "amount": 0,
                "bookCount": 0,
                "date": new Date(),
                "scannedAmount": 0,
                "scannedCount": 0,
                "storeId": this.getUser().activeStore.id ,
                "userId": this.getUser().id,
                "lotteryBooks": []
            }
            this.isShowDialog = ! this.isShowDialog
        },

        toggleEditDialog() {
            this.isShowEditDialog = ! this.isShowEditDialog
        },

        async setSalesBook(book) {
            this.salesBook = book
        },
        
        async showSalesDialog(book) {
            await this.setSalesBook(book)
            await this.$refs.booksales.fetchSalesRecords()
            this.isShowSalesDialog = true
        },

        addBookSettledField() {
            this.records.forEach(book => {
                book.settled = false
                if (book.bookSettlementDate != null) {
                    book.settled = true
                }
                if (book.createdDate == null) {
                    book.createdDate = moment(new Date()).format('YYYY-MM-DD')
                }
            });
        },

        addInventoryCountAndValue() {
            this.records.forEach(book => {
                if (book.lastTicketSold === null) {
                    book.lastTicketSold = 0
                }
                book["inventoryCount"] = book.ticketCount - book.lastTicketSold
                book["inventoryValue"] = book.inventoryCount * book.pricePoint
            });
        },

        addBookReturnedField() {
            this.records.forEach(book => {
                if (book.returnedDate != null) {
                    book["returnedDateObj"] = moment(book.returnedDate).toDate()
                }
            });
        },

        settleBook() {
            // console.log("Settling book")
            //
        },

        saveEditRecord() {

            this.book = {...this.tempBook}

            switch (this.book.status) {
                case "RECEIVED":
                    // this.book.startSalesId = null
                    // this.book.location = null
                    this.book.saleRackLoc = null
                    this.book.closedDate = null
                    this.book.returnedDate = null
                    break                
                case "MACHINE":
                    this.book.status = "ONSALE"
                    this.book.location = "M"
                    if (this.book.activatedDate == null) {
                        this.book.activatedDate = new Date().toISOString().split('T')[0]
                    }
                    this.book["inventoryCount"] = this.book.ticketCount - this.book.lastTicketSold
                    this.book["inventoryValue"] = this.book.totalValue - (this.book.inventoryCount  * this.book.pricePoint)
                    this.book.closedDate = null
                    this.book.returnedDate = null
                    break
                case "ONSALE":
                    this.book.location = "R"
                    this.book.closedDate = null
                    this.book.returnedDate = null
                    break
                case "CLOSED":
                    if (this.book.closedDate == null) {
                        this.book.closedDate=new Date().toISOString().split('T')[0]
                    }
                    this.book.returnedDate = null
                    break
                case "RETURNED":
                    if (this.book.returnedDate == null) {
                        this.book.returnedDate = moment().format("YYYY-MM-DD")
                    } else {
                        this.book.returnedDate = moment(this.book.returnedDateObj).format("YYYY-MM-DD")
                    }
                    this.book.closedDate = null
                    break
                case "SETTLED":
                    if (this.book.bookSettlementDate == null) {
                        this.book.bookSettlementDate = new Date().toISOString().split('T')[0]
                    } else {
                        this.book.bookSettlementDate = this.book.bookSettlementDate.toISOString().split('T')[0]
                    }
                    break                    
            }

            this.updateLotteryBook(this.book)
        },

        binsArray() {
            var ans = [];
            // To Identify Machine when Multiple Machines at store
            if (this.fetchStatus == "ONSALE") {
                for (let i = 1; i <= 99; i++) {
                    if (i < 10) {
                        ans.push("0"+i.toString())
                    } else {
                        ans.push(i.toString())
                    }                
                }
                return ans;
            }

            var machineArray = ["A","B","C","D","E"]
            machineArray.forEach(machine => {
                for (let i = 1; i <= 50; i++) {
                    if (i < 10) {
                        ans.push(machine+"0"+i.toString())
                    } else {
                        ans.push(machine+i.toString())
                    }                
                }
            })

            return ans;
        },

        binsArray1() {
            var ans = [];
            for (let i = 1; i <= 99; i++) {
                if (i < 10) {
                    ans.push("0"+i.toString())
                } else {
                    ans.push(i.toString())
                }                
            }
            return ans;
        },

        cancelEditRecord() {
            this.toggleEditDialog()
        },

        cancelSalesDialog() {
            this.isShowSalesDialog = false
        },

        saveRecord1() {
            if (this.newTickets.length > 0) {

                this.invoice.lotteryBooks = this.newTickets

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId 
                    + '/lottery/invoice/'
                axios.post(url,
                    this.invoice,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        this.$buefy.toast.open({
                            message: 'Saved ' + this.newTickets.length +' Instant Lottery Books ' ,
                            type: 'is-success'
                        })
                        this.fetchRecords()

                    })
                    .catch( (error) => {
                        if (error.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'Failed to save ' + this.newTickets.length + ' books ' + error.message,
                                type: 'is-warning'
                            })
                        }
                    }) 

            }

        },

        saveRecord() {
            if (this.newTickets.length > 0) {

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId 
                    + '/lottery/books/'
                axios.post(url,
                    this.newTickets,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                        this.$buefy.toast.open({
                            message: 'Saved ' + this.newTickets.length +' Instant Lottery Books ' ,
                            type: 'is-success'
                        })
                        this.fetchRecords()

                    })
                    .catch( (error) => {
                        if (error.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'Failed to save ' + this.newTickets.length + ' books ' + error.message,
                                type: 'is-warning'
                            })
                        }
                    }) 

            }

        },

        updateLotteryBook(bookDetails) {

                const url = process.env.VUE_APP_API_ROOT_URL + '/lottery/books/' +  bookDetails.id
                axios.put(url,
                    bookDetails,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.$buefy.toast.open(`Lottery book ${bookDetails.bookNumber} updated. Status ${bookDetails.status}`)
                        this.fetchRecords()
                    })
                    .catch( (error) => {
                        if (error.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'Failed to save ' + bookDetails.saleRackLoch + ' books ' + error.message,
                                type: 'is-warning'
                            })
                        }
                    }) 

        },
              
        editBin(row) {
            this.$buefy.dialog.prompt({
                message: `Bin Number?`,
                inputAttrs: {
                    placeholder: 'e.g. Bin Number',
                    maxlength: 10,
                    value: row.saleRackLoc
                },
                trapFocus: true,
                onConfirm: (value) => this.updateBin(row,value)
            })
        },

        updateBin(row,value) {
            if (row.saleRackLoc != value) {
                row.saleRackLoc = value
                this.updateLotteryBook(row)
            }
        },

        doAction(action, row) {

            this.action = action
            this.storeId = this.user.activeStore

            switch(this.action) {
                case 'add':
                    this.newTickets = []
                    this.toggleDialog()
                    break

                case 'edit':
                    this.book = row
                    if (this.book.status == "ONSALE") {
                        if (this.book.location == "M") {
                            this.book.status = "MACHINE"
                        }
                    }

                    this.cloneBook()

                    this.toggleEditDialog()
                    break
            }
        },

        cloneBook() {
            this.tempBook = {...this.book}
        },
        
        ticketStatus(row) {
            // RECEIVED, ONSALE, CLOSED, SETTLED, VOID, RETURNED,LOST,MACHINE
            switch (row.status) {
                case "RECEIVED":
                    return "In Safe"
                case "ONSALE":
                    if (row.location == "M") {
                        return "Outside Sale"
                    } else {
                        return "Inside Sale"
                    }
                case "MACHINE":
                    return "Outside Sale"
                case "ONHOLD":
                    return "In Safe"
                case "RETURNED":
                    return "Returned"
                case "CLOSED":
                    return "Closed"
                case "SETTLED":
                    return "Settled"                    
                default:
                    return "Unknown"
            }
        },

        togglePagination() {
            if (this.isPaginated) {
                this.isPaginated = false
            } else {
                this.isPaginated = true
            }
        },

        toggleShowStoreName() {
            if (this.isPrinting) {
                this.isPrinting = false
            } else {
                this.isPrinting = true
            }
        },

        setStoreState() {
            UserStore.commit('fetchStoreState', UserStore.getters.getActiveStore.storeId)
            if (!UserStore.getters.getStoreState.id) {
                UserStore.commit('fetchStoreState', UserStore.getters.getActiveStore.storeId)
            }
        },
        
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },

        setDateRange(dateRange) {
            this.closeDateRange = [dateRange[0], dateRange[1]]
        }, 

    },

    computed: {

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        totalInstantValue() {
            return this.filteredRecords.reduce((accum,item) => accum + parseFloat(item.ticketCount * item.pricePoint), 0)
        },

        totalSoldValue() {
            return this.filteredRecords.reduce((accum,item) => accum + parseFloat(((item.lastTicketSold == item.ticketCount ? item.lastTicketSold : (item.lastTicketSold ? item.lastTicketSold  : 0))) * item.pricePoint), 0)
        },

        totalInventoryValue() {
            return this.totalInstantValue - this.totalSoldValue
        },

        fetchStatusSelected() {
            // RECEIVED, ONSALE, CLOSED, SETTLED, VOID, RETURNED,LOST,MACHINE
            var today = new Date()
            var reportDate =  (today.getMonth()+1) + "/" + today.getDate() + "/" + today.getFullYear()
            var startDate = this.closeDateRange[0]
            var endDate = this.closeDateRange[1]

            var dateRange = (startDate.getMonth()+1) + "/" + startDate.getDate() + "/" + startDate.getFullYear() + " - " + (endDate.getMonth()+1) + "/" + endDate.getDate() + "/" + endDate.getFullYear()
            switch (this.fetchStatus) {
                case "RECEIVED":
                    return "IN SAFE - " + reportDate
                case "ONSALE":
                    return "INSIDE SALE - " + reportDate
                case "MACHINE":
                    return "OURSIDE SALE - " + reportDate
                case "ONHOLD":
                    return "ON HOLD - " + reportDate
                case "RETURNED":
                    return "RETURNED - " + dateRange
                case "CLOSED":
                    return "CLOSED - " + dateRange
                default:
                    return "Unknown"
            }
        },

        filteredRecords() {
            var filteredArray = []

            switch (this.soldTickets) {
                case "full":
                    filteredArray = this.records.filter(item => (item.lastTicketSold == null || item.lastTicketSold == 0 ))
                    break;
                case "partial":
                    filteredArray = this.records.filter(item => (item.lastTicketSold > 0))
                    break;
                case "scanDate1":
                    var startDate = this.closeDateRange[0].toISOString().split('T')[0]
                    var endDate = this.closeDateRange[1].toISOString().split('T')[0]

                    filteredArray = this.records.filter(item => (item.createdDate.substr(0,10) >= startDate && item.createdDate.substr(0,10) <= endDate))
                    break                  
                default:
                    filteredArray = this.records
            }

            return filteredArray
        },
    },

    watch: {
        closeDateRange: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                
                this.fetchRecords()

            } else {
                this.isLoading = false
            }
        },

        soldTickets() {
            if (this.soldTickets == "scanDate") {
                this.records = []
                this.fetchRecords()
            } else {
                var today = new Date()
                var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
                var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
                this.closeDateRange=[firstDay, lastDay]
            }
        }

    },

    mounted() {

        this.user = this.getUser()
        var today = new Date()
        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.closeDateRange=[firstDay, lastDay]
        // this.setStoreState()
    }     

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn-label-text {
    padding-left: 5px;
}

.unerline {

    box-shadow: 1px 1px 1px 1px gray;
    cursor: pointer;    
}

.capitalize {
  text-transform: capitalize;
}

.veecli-table {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 2em;
}

.veecli-table-heading {
    padding-bottom: 2em;
    height: 2em;
    background-color: #f2f2f2d7;
    font-weight: bold;
    font-size: larger;
    text-align: center;
    vertical-align: middle;
}
.veecli-table-subhead {
    border-bottom: 0.5px;
    padding-bottom: 2.5em;
    height: 2em;
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}

.veecli-receipt-table {
    padding-top: .8em;
}
</style>