<template>
    <article class="columns">
      <section class="column">
        <!-- <div class="modal" style="width: 100%" v-bind:class="{ 'is-active': isShowSalesDialog }"> -->
        <b-modal
            v-model="isShowSalesDialog"
            has-modal-card
            full-screen
            :can-cancel="false"
            @close="closeDialog"
            >
        <div class="modal-background"></div>
        <div class="modal-card scrollable" style="width: auto">
            <!-- <form action="" class="box" @submit.prevent="submitForm"> -->
            <header class="modal-card-head">
            <p class="modal-card-title has-text-centered">INSTANT LOTTERY DETAILS</p>
            <button class="delete is-danger" type="button" @click="closeDialog">Close</button>
            </header>
            <section class="modal-card-body" v-if="book">
                <!-- {{  book }} -->
                <div class="box">
                <div class="columns has-text-weight-bold">
                    <div class="column is-3">
                        Book : <br>{{ book.gameNumber }}
                    </div>
                    <div class="column is-3">
                        Game Name : <br>{{ book.gameName }}
                    </div>
                    <div class="column is-3">
                        Book Number : <br>{{ book.bookNumber }}
                    </div>
                    <div class="column is-3">
                        Status:<br>{{  bookStatus() }}
                    </div>
                </div>
                <div class="columns has-text-weight-bold">
                    <div class="column is-3">
                        Received Date: <br>{{ book.createdDate | formatDateTime }}
                    </div>
                    <div class="column is-3">
                        Activated Date : <br>{{  book.activatedDate | formatDate }}
                    </div>
                    <div class="column is-3" v-if="book.closedDate">
                        Closed Date: <br>{{ book.closedDate | formatDate }}
                    </div>
                    <div class="column is-3" v-if="book.returnedDate">
                        Returned Date: <br>{{ book.returnedDate | formatDate }}
                    </div>
                    <div class="column is-3" v-if="book.bookSettlementDate">
                        Settled Date: <br>{{ book.bookSettlementDate | formatDate }}
                    </div>
                </div>

                <div class="columns has-text-weight-bold">
                    
                    
                </div>

                <div class="columns has-text-weight-bold">
                    <div class="column is-3">
                        Book Value : <br>{{ book.totalValue | formatCurrency }}
                    </div>
                    <div class="column is-3">
                        Sold Value: <br>{{ book.lastTicketSold * book.pricePoint | formatCurrency }}
                    </div>
                    <div class="column is-3">
                        Inventory Count: <br>{{ book.inventoryCount }}
                    </div>
                    <div class="column is-3">
                        Inventory Value: <br>{{ book.inventoryValue | formatCurrency}}
                    </div>
                </div>
                

                <div class="is-fullwidth box has-text-centered"  v-if="records.lotteryBookSales.length == 0">
                    <b-message type="is-info" has-icon>
                        NO SALES RECORDS FOUND
                    </b-message>
                </div>
                <div v-else>
                    <!-- <div class="title has-text-centered">
                        TICKET SALES
                    </div> -->
                    <div class="is-fullwidth" v-if="records">
                        <b-table class="control has-text-weight-semibold"
                            :data='records.lotteryBookSales'
                            :paginated="isPaginated"
                            :per-page="perPage"
                            paginationPosition="both"
                            sortIcon="arrow-up"
                            sortIconSize="is-small"
                            default-sort=closingDate
                            defaultSortDirection='desc'
                            >
                            <template>
                    
                                <b-table-column field="startingSeq" label="Date" sortable sorticon v-slot="props">
                                    {{ props.row.salesSummary.closingDate | formatDate}}
                                </b-table-column>
                                <b-table-column field="periodBeginDate" label="Shift Time" v-slot="props">
                                    {{ props.row.salesSummary.periodBeginDate |formatDateTime}} - {{ props.row.salesSummary.periodEndDate |formatDateTime}}
                                </b-table-column>
                                <b-table-column numeric field="startingSeq" label="Start Seq" v-slot="props">
                                    {{ props.row.startingSeq }}
                                </b-table-column>
                                <b-table-column numeric field="endingSeq" label="End Seq" v-slot="props">
                                    {{ props.row.endingSeq }}
                                </b-table-column>    
                                <b-table-column numeric field="ticketsSold" label="Tickets Sold" v-slot="props">
                                    {{ props.row.ticketsSold }}
                                </b-table-column>
                                <b-table-column numeric field="ticketsSold" label="Sales Amount" v-slot="props">
                                    {{ book.pricePoint * props.row.ticketsSold | formatCurrency }}
                                </b-table-column>
                            </template>
                            <template slot="empty">
                                <data-loading 
                                    :isLoading="isLoading" 
                                    :isFullPage="isFullPage"> 
                                </data-loading>
                            </template>
                            <template slot="top-left">
                                <div class="ml-5 is-size-4">
                                    TICKET SALES
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
            </section>
    
            <footer class="modal-card-foot">
            <!-- <button class="button is-success" type="button" @click="submitForm" :disabled='isDisabled'>Save</button> -->
            <button class="button" type="button" @click="cancelForm">Close</button>
            <span class='is-danger'></span>
            </footer>
        <!-- </form> -->
    
        </div>
        </b-modal>
      </section>
    </article>
    </template>
    
    
    <script>
    
    import axios from 'axios'
    import UserStore from "../../../../../store/UserStore"
    import DataLoading from '../../../../../components/app/DataLoading'

    export default {

        props: {
             book: Object,
             isShowSalesDialog: Boolean
        },

        components: {
            DataLoading
        },
    
        data() {
            return {
                trueValue: true,
                falseValue: false,
                records: {
                        lotteryBookSales: []
                    },
                isPaginated: true,
                perPage: 20,
                isFullPage: true,
            }
        },
    
        methods: {
    
            closeDialog() {
    
                this.$emit('hide-dialog')
    
            },
    
            cancelForm() {
                this.closeDialog()
            },

            fetchSalesRecords() {
                
                if (this.book !=null && UserStore.getters.getUser.activeStore) {

                    // const loadingComponent = this.$buefy.loading.open({
                    //     container: this.isFullPage ? null : this.$refs.element.$el
                    // })

                    var url = process.env.VUE_APP_API_ROOT_URL + "lottery/book/?id=" + this.book.id

                    axios.get(url,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                                'Refresh-Token': UserStore.getters.getRefreshToken,
                                'correlation-id': this.$uuid.v1()
                            },                   
                        })
                        .then( (response) => {
                            if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                            this.records = response.data.data
                            this.isLoading = false
                        })
                        .catch( (err) => {
                            this.records = {
                                lotteryBookSales: []
                            }
                            return err
                        })
                    // loadingComponent.close()

                } else {
                    console.log("no active store")
                }
            },

            bookStatus() {
                switch (this.book.status) {
                    case "RECEIVED":
                        return "In Safe"
                    case "CLOSED":
                        return "Closed"
                    case "RETURNED":
                        return "Returned"
                    case "SETTLED":
                        return "Settled"
                    case "ONSALE":
                        if (this.book.location == "R") {
                            return "Inside Sale - Bin: " + this.book.saleRackLoc
                        } else {
                            return "Ourside Sale"
                        }
                }
            }

        },
    
        computed: {
            
        },
    
        mounted() {
            
        }
    
    }
    </script>
    
    <style scoped>
    
    /* .scrollable {
        max-height: 200vw;
        width: 400px;
        overflow: auto; 
    } */
    .veecli-select select { 
        /* Whatever color  you want */
        background-color: rgb(250, 250, 242);
    }
    
    
    </style>