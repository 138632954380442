<template>

<div class="section">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowGameDialog }">
        <div class="modal-background"></div>
        <div class="modal-card scrollable" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">New Instant Lottery Game</p>&nbsp;&nbsp;
                <button class="delete is-danger" type="button" @click="closeGameDialog">Cancel</button>
            </header>
            <section class="modal-card-body">
                <b-message type="is-warning" has-icon>
                    <template #header>
                            Game Number <b-tag class="is-primary">{{ game.gameNumber }}</b-tag> not found!
                    </template>
                    Please confirm Game Number and complete below to Add New Game.<br>
                    Cancel if entered Game Number is incorrect.
                </b-message>
                <div class="columns has-background-white-ter has-text-centered">
                    <div class="column">
                        <b-field  label="Game Number" label-for="gameNumber">
                            {{ game.gameNumber }}
                        </b-field> 
                    </div>
                    <div class="column">
                        <b-field  label="Country" label-for="countryCode">
                            {{ game.countryCode }}
                        </b-field> 
                    </div>
                    <div class="column">
                        <b-field  label="State/Province" label-for="stateCode">
                            {{ game.stateCode }} {{ game.stateName }}
                        </b-field> 
                    </div>
                    <!-- <div class="column"> 
                        <b-field label="State"> 
                            <b-select readonly v-model="game.stateId" placeholder="Select a State">
                                <option
                                    v-for="option in states"
                                    :value="option.id"
                                    :key="option.id">
                                    {{ option.code}} {{ option.name }} 
                                </option>
                            </b-select>                            
                        </b-field> 
                    </div> -->                   
                    <div class="column">
                        <b-field  label="Tickets in Book" label-for="ticketCount">
                            {{ game.ticketCount }}
                        </b-field> 
                    </div>

                    <div class="column">
                        <b-field  label="Total Book Value" label-for="totalBookValue">
                            {{ game.totalBookValue | formatCurrency }}
                        </b-field> 
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-field  label="Game Name" label-for="name">
                            <b-input
                                v-model="game.name" 
                                ref="lotteryBookName"
                                @focus="$event.target.select()"
                                required
                                placeholder="Name of Instant Lottery">
                                >
                            </b-input>
                        </b-field> 
                    </div>
                </div>

                <div class="columns has-text-right">
                    <div class="column">
                        <b-field numeric label="Sequence Start" label-for="defaultSeqBeg">
                            <b-input custom-class="has-text-right"
                                v-model="game.defaultSeqBeg" 
                                :min="seqMin"
                                :max="seqMax"
                                required
                                @input="reCalculate()"
                                @focus="$event.target.select()"
                                placeholder="Starting Sequence Number">
                                >
                            </b-input>
                        </b-field> 
                    </div>
                    <div class="column has-text-right">
                        <b-field numeric label="Sequence End" label-for="defaultSeqEnd">
                            <b-input custom-class="has-text-right"
                                v-model="game.defaultSeqEnd" 
                                :min="seqMin"
                                :max="seqMax"
                                required
                                @input="reCalculate()"
                                @focus="$event.target.select()"
                                placeholder="Ending Sequence Number ">
                                >
                            </b-input>
                        </b-field> 
                    </div>
                    <div class="column has-text-right">
                        <b-field numeric label="Price Per Ticket" label-for="pricePoint">
                            <b-input custom-class="has-text-right"
                                v-model="game.pricePoint" 
                                required
                                @input="reCalculate()"
                                @focus="$event.target.select()"
                                placeholder="Price per ticket">
                                >
                            </b-input>
                        </b-field> 
                    </div>
                </div>

                <footer class="modal-card-foot">
                <button class="button is-success" type="button" @click="submitGameForm" :disabled='!game.totalBookValue > 0.00 || game.name == ""'>Save</button>
                <button class="button" type="button" @click="closeGameDialog">Cancel</button>
                <span class='is-danger'></span>
                </footer>        

            </section>
        </div>
    </div>
</div>
    
</template>

<script>
export default {

    props: {
        isShowGameDialog: Boolean,
        game: Object,
        states: Array,
        storeState: Object
    },

    data() {
        return {
            showGameAlert: true,
            isSaveDisabled: false,
            seqMin: 0,
            seqMax: 0
        }
    },

    methods: {
        closeGameDialog() {
            this.$emit('toggle-game-dialog')
        },

        submitGameForm() {
            this.$emit('save-game-record')
        },

        setFocusOnName() {
            // console.log("Dialog Showing")
            this.$refs.lotteryBookName.focus()
        },

        reCalculate() {
            this.game.ticketCount = parseInt(this.game.defaultSeqEnd) - parseInt(this.game.defaultSeqBeg) + 1
            this.game.totalBookValue = this.game.ticketCount * parseFloat(this.game.pricePoint)
            if (isNaN(this.game.ticketCount)) this.game.ticketCount = 0

            if (isNaN(this.game.totalBookValue)) this.game.totalBookValue = 0.00
        }
    },


}
</script>
