<template>
<div class="section">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>

</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../../store/UserStore"
import * as fontkit from '@btielen/pdf-lib-fontkit';

export default {
    props: {
        isShowPDFDialog: Boolean,
        fetchStatus: String,
        filteredRecords: [],
        dateRange: [],
        isShowBarcode: Boolean
    },

    data() {
        return {
            sortedRecords: [],
            isShowPDFDialogLocal: false,
            totalBookValue: 0,
            totalBookCount: 0,
            totalSoldValue: 0,
            totalBalanceValue: 0,
            row: 660,
            lotteryCount: 0,
            barcodeFont: "",
            pageNumber: 1,
            pngUrl: '/images/veecli-logo.png',
            pngImageBytes: "",
            pngImage: "",
            pngDims: "",
            countryCurrencyFormat: UserStore.getters.getCountryCurrencyFormat            
            // countryCurrencyFormat: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'})
        }

    },

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        async createPdf() {

            const pdfDoc = await PDFDocument.create()
            this.pngUrl = '/images/veecli-logo.png'
            this.pngImageBytes = await fetch(this.pngUrl).then((res) => res.arrayBuffer())

            this.pngImage = await pdfDoc.embedPng(this.pngImageBytes)
            this.pngDims = await this.pngImage.scale(0.08)
            
            pdfDoc.registerFontkit(fontkit)

            var Encoder = require("code-128-encoder")
            var encoder= new Encoder()
            const url = '/fonts/LibreBarcode128-Regular.otf'
            const fontBytes = await fetch(url).then(res => res.arrayBuffer())
            this.barcodeFont = await pdfDoc.embedFont(fontBytes)
            
            var page = pdfDoc.addPage(PageSizes.Letter)

            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
            page.setFont(helveticaFont)

            this.pageHeader(page,helveticaFont)
            // await this.veecliLogo(pdfDoc, page)

            this.totalBookValue = 0
            this.totalBookCount = 0
            this.totalSoldValue = 0
            this.totalBalanceValue = 0               
            this.row = 660
            this.pageNumber = 1
            this.lotteryCount = 0
            var gthis = this
            this.sortedRecords.forEach(async function(lottery){

                if (gthis.lotteryCount% 35 == 0 && gthis.lotteryCount < 0) {

                    gthis.pageFooter(pdfDoc, page)
                    gthis.pageNumber++

                    page = pdfDoc.addPage(PageSizes.Letter)
                    gthis.pageHeader(page,helveticaFont)

                    // Set Line to start records
                    gthis.row = 660

                }
                var price = gthis.countryCurrencyFormat.format(lottery.pricePoint)
                var ticketSold = lottery.lastTicketSold  ? (lottery.lastTicketSold == lottery.ticketCount ? lottery.lastTicketSold : lottery.lastTicketSold ) : 0
                var ticketNotSold = lottery.ticketCount - ticketSold

                gthis.totalBookValue += lottery.ticketCount * lottery.pricePoint
                gthis.totalBookCount ++
                gthis.totalSoldValue += ticketSold * lottery.pricePoint
                gthis.totalBalanceValue += ticketNotSold * lottery.pricePoint 
               
                page.drawText("[  ]  " + lottery.gameNumber.toString(), { 
                    x: 20, 
                    y: gthis.row+6, 
                    size: 10, 
                    })

                page.drawText(lottery.bookNumber.toString(), { 
                    x: 70, 
                    y: gthis.row+6, 
                    size: 10, 
                    })

                if (lottery.gameName === undefined) {
                    gameName = ""
                } else {
                    var gameName = lottery.gameName.toString().toLowerCase().substr(0, 18)
                    gameName = gameName.replace(/\b\w/g, function(c) {
                                    return c.toUpperCase();
                                })
                }

                page.drawText(gameName, { 
                    x: 120, 
                    y: gthis.row+6, 
                    size: 10, 
                    })

                if (gthis.fetchStatus == "ONSALE" || gthis.fetchStatus == "MACHINE") {
                    if (lottery.saleRackLoc === null) {
                        lottery.saleRackLoc = ""
                    }
                    page.drawText(lottery.saleRackLoc.toString(), { 
                        x: 240, 
                        y: gthis.row+6, 
                        size: 10, 
                        })                    
                } 

                // page.drawText(lottery.ticketStartNum.toString() + '-' + lottery.ticketEndNum.toString(), { 
                //     x: 250, 
                //     y: gthis.row+6, 
                //     size: 10, 
                //     })

                textWidth = helveticaFont.widthOfTextAtSize(lottery.ticketCount.toString(), 10);

                page.drawText(lottery.ticketCount.toString(), { 
                    x: 310 - textWidth, 
                    y: gthis.row+6, 
                    size: 10,
                    TextAlignment: 2,
                    })   

                var textWidth = helveticaFont.widthOfTextAtSize(price, 10);

                page.drawText(price, { 
                    x: 365 - textWidth, 
                    y: gthis.row+6, 
                    size: 10,
                    TextAlignment: 2,
                    })

                textWidth = helveticaFont.widthOfTextAtSize(lottery.totalValue.toString(), 10);
                // const textHeight = helveticaFont.heightAtSize(24);

                page.drawText(gthis.countryCurrencyFormat.format(lottery.totalValue), { 
                    x: 400 - textWidth, 
                    y: gthis.row+6, 
                    size: 10,
                    TextAlignment: 2,
                    })   

                textWidth = helveticaFont.widthOfTextAtSize(ticketSold.toString(), 10);

                page.drawText(ticketSold.toString(), { 
                    x: 450 - textWidth, 
                    y: gthis.row+6, 
                    size: 10,
                    TextAlignment: 2,
                    })   

                textWidth = helveticaFont.widthOfTextAtSize(ticketNotSold.toString(), 10);

                page.drawText(ticketNotSold.toString(), { 
                    x: 485 - textWidth, 
                    y: gthis.row+6, 
                    size: 10,
                    TextAlignment: 2,
                    }) 

                if (gthis.isShowBarcode) {
                    var barCodeText = lottery.gameNumber.toString() + lottery.bookNumber.toString() + (lottery.lastTicketSold ? String(lottery.lastTicketSold).padStart(3, '0')  : '000')
                    var barCode = encoder.encode(barCodeText)
                    page.drawText(barCode, { 
                        x:500, 
                        y: gthis.row+2, 
                        size: 28,
                        font: gthis.barcodeFont,
                        })
                } else {

                    var ticketSoldValue = ticketSold * lottery.pricePoint
                    textWidth = helveticaFont.widthOfTextAtSize(ticketSoldValue.toString(), 10);

                    page.drawText(gthis.countryCurrencyFormat.format(ticketSoldValue), { 
                        x: 515 - textWidth, 
                        y: gthis.row+6, 
                        size: 10,
                        TextAlignment: 2,
                        })

                    var ticketNotsoldValue = lottery.totalValue - ticketSoldValue
                    textWidth = helveticaFont.widthOfTextAtSize(ticketNotsoldValue.toString(), 10);
                    page.drawText(gthis.countryCurrencyFormat.format(ticketNotsoldValue), { 
                        x: 575 - textWidth, 
                        y: gthis.row+6, 
                        size: 10,
                        TextAlignment: 2,
                        })
                }

                gthis.row -= 3
                page.drawLine({
                    start: { x: 10, y: gthis.row },
                    end: { x: 600, y: gthis.row },
                    thickness: .5,
                    opacity: 0.20,
                })                 

                gthis.row -= 25
                gthis.lotteryCount++

                if (gthis.row < 50) {
                        gthis.pageFooter(pdfDoc, page)
                        gthis.pageNumber++

                        page = pdfDoc.addPage(PageSizes.Letter)
                        gthis.pageHeader(page,helveticaFont)

                        // Set Line to start records
                        gthis.row = 660                
                }
            });

            this.pageTotal(page)
            this.pageFooter(pdfDoc,page)

            const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true })
            document.getElementById('pdf').src = pdfDataUri
        },

        pageHeader(page,helveticaFont) {

            var lotteryStatus = ""

            switch (this.fetchStatus) {
                case "RECEIVED":
                    lotteryStatus = "In Safe"
                    break
                case "ONSALE":
                    lotteryStatus = "Inside Sale"
                    break
                case "MACHINE":
                    lotteryStatus = "Outside Sale"
                    break                    
                case "CLOSED":
                    lotteryStatus = "Closed"
                    break
                case "RETURNED":
                    lotteryStatus = "Returned"
                    break                                                            
                case "SETTLED":
                    lotteryStatus = "Settled"
                    break                        
            }

            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = helveticaFont.widthOfTextAtSize(storeName, 15);

            page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 15, 
                })

            var reportHeading = "Instant Lottery Report - " + lotteryStatus
            textWidth = helveticaFont.widthOfTextAtSize(reportHeading, 13);

            page.drawText(reportHeading, { 
                x: 300 - (textWidth/2), 
                y: 730, 
                size: 13, 
                })

            if (["CLOSED", "RETURNED", "SETTLED"].includes(this.fetchStatus)) {
                var dateRange = moment(this.dateRange[0]).format('MM/DD/YYYY hh:mm a') + " - " + moment(this.dateRange[1]).format('MM/DD/YYYY hh:mm a')
                textWidth = helveticaFont.widthOfTextAtSize(dateRange, 11);

                page.drawText(dateRange, { 
                    x: 300 - (textWidth/2), 
                    y: 710, 
                    size: 11, 
                    })
            }

            this.row = 690
            // Print Column Heading

            page.drawText('Game', { 
                x: 30, 
                y: this.row, 
                size: 11, 
                })

            page.drawText('Book', { 
                x: 70, 
                y: this.row, 
                size: 11, 
                })

            page.drawText('Name', { 
                x: 120, 
                y: this.row, 
                size: 11, 
                })

            if (this.fetchStatus == "ONSALE" || this.fetchStatus == "MACHINE") {
                page.drawText("Bin", { 
                    x: 240, 
                    y: this.row, 
                    size: 10, 
                    })                    
            }  
            // page.drawText('Seq', { 
            //     x: 250, 
            //     y: this.row, 
            //     size: 11, 
            //     })

            page.drawText('Tickets', { 
                x: 285, 
                y: this.row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Price', { 
                x: 340, 
                y: this.row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Value', { 
                x: 390, 
                y: this.row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Sold', { 
                x: 430, 
                y: this.row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Bal.', { 
                x: 465, 
                y: this.row, 
                size: 11,
                TextAlignment: 2
                })

            if (this.isShowBarcode) {
                page.drawText('Barcode', { 
                    x: 500, 
                    y: this.row, 
                    size: 11, 
                    })
            } else {
                page.drawText('Sales', { 
                    x: 508, 
                    y: this.row, 
                    size: 11, 
                    })
                page.drawText('Inventory', { 
                    x: 550, 
                    y: this.row, 
                    size: 11, 
                    })                                    
            }
            

            this.row -= 4
            page.drawLine({
                start: { x: 10, y: this.row },
                end: { x: 600, y: this.row },
                thickness: 1,
                opacity: 0.75,
            })                
                
        },

        veecliLogo: async(pdfDoc, page) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                // opacity: 0.75,
            })
            page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })                    
        },

        pageFooter(pdfDoc, page) {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

            page.drawImage(this.pngImage, {
                x: 300,
                y: 18,
                width: this.pngDims.width,
                height: this.pngDims.height,
                rotate: degrees(0),
                // opacity: 0.75,
            })
            page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

            page.drawText('Page ' + this.pageNumber, { 
                x: 540, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

            // this.veecliLogo(pdfDoc, page)
        },
        
        pageTotal(page) {

            page.drawLine({
                start: { x: 10, y: this.row },
                end: { x: 600, y: this.row },
                thickness: 1,
                opacity: 0.75,
            })  
            this.row -= 15

            page.drawText('Book Count: ' + this.totalBookCount.toString(), { 
                x: 30, 
                y: this.row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Book Value: ' + this.countryCurrencyFormat.format(this.totalBookValue), { 
                x: 140, 
                y: this.row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Sold Value: ' + this.countryCurrencyFormat.format(this.totalSoldValue), { 
                x: 290, 
                y: this.row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Balance Value: ' + this.countryCurrencyFormat.format(this.totalBalanceValue), { 
                x: 420, 
                y: this.row, 
                size: 11,
                TextAlignment: 2
                })

            this.row -= 10
            page.drawLine({
                start: { x: 10, y: this.row },
                end: { x: 600, y: this.row },
                thickness: 1,
                opacity: 0.75,
            })  

        },

        loadData() {
            this.sortedRecords = this.filteredRecords.sort(function(a, b){     // => [ 4, 33, 222, 1111 ]
                return (a.gameNumber+a.bookNumber)-(b.gameNumber+b.bookNumber)
            })
            this.isShowPDFDialogLocal = true
            this.createPdf()
        },

    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>