<template>
<div class="section">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
        <div class="modal-background"></div>
        <b-modal
        v-model="isShowDialog"
        has-modal-card
        full-screen
        :can-cancel="false"
        @close="closeDialog"
        >
        <div class="modal-card scrollable">
        <!-- <div class="modal-card scrollable" style="width: auto"> -->
            <header class="modal-card-head">
                <p class="modal-card-title">Inventory Instant Tickets</p>&nbsp;&nbsp;
                <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
            </header>
            <section class="modal-card-body">
                <div class="columns box">

                    <div class="column is-3">
                        <div class="column has-background-grey-lighter has-text-centered has-text-default is-size-5 has-text-weight-bold">
                            Scan Bar Code/Type all numbers<br>                            
                        </div>
                        <div>&nbsp;</div>
                        <div v-show="inputMethod == 'Scan Book Bar Code'">

                            <b-field>
                                <b-input class="scanInput"
                                    v-model="scannedBookBarCode" 
                                    v-focus
                                    icon-pack="fas"
                                    icon="barcode"
                                    ref="scannedBookBarCode"
                                    @keyup.native.enter="processScannedInput"
                                    @focus="$event.target.select()"
                                    placeholder="Scan Instant Ticket Bar Code">
                                    >
                                </b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div class="column is-3">
                        <!-- <div class="columns">
                            <div class="column is-6">
                                Invoice Number:
                            </div>
                            <div class="column is-6 has-text-right">
                                <VeeInput
                                    type="text" 
                                    v-model="invoice.number"
                                    ref="invoice.number"
                                    name="invoice.number"
                                    width="30"
                                    :value="invoice.number"
                                    :editable="isEditable"
                                    :countryCurrencyCode="countryCurrencyCode"
                                    />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-7">
                                Invoice Book Count:
                            </div>
                            <div class="column is-5 has-text-right">
                                <VeeInput
                                    placeholder="Books"
                                    type="number" 
                                    v-model="invoice.bookCount"
                                    name="invoice.bookCount"
                                    :value="invoice.bookCount"
                                    :editable="isEditable"
                                    />
                            </div>
                        </div> -->
                        <div class="columns">
                            <div class="column is-7">
                                Scanned Book Count:
                            </div>
                            <div class="column is-5 has-text-right">
                                <VeeInput
                                    placeholder="Books"
                                    type="number" 
                                    v-model="invoice.scannedCount"
                                    name="invoice.bookCount"
                                    :value="invoice.scannedCount"
                                    />
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <!-- <div class="columns">
                            <div class="column is-6">
                                Invoice Date:
                            </div>
                            <div class="column is-6 has-text-right">
                                <b-datepicker
                                    ref="invoice.date"
                                    :mobile-native="isMobileNativeDatePicker"
                                    icon="calendar"
                                    placeholder="Click to select..."
                                    v-model="invoice.date">
                                </b-datepicker>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                Invoice Amount:
                            </div>
                            <div class="column is-6 has-text-right">
                                <VeeInput
                                    type="currency" 
                                    v-model="invoice.amount"
                                    name="invoice.amount"
                                    :value="invoice.amount"
                                    :editable="isEditable"
                                    :countryCurrencyCode="countryCurrencyCode"
                                    />
                            </div>
                        </div> -->
                        <div class="columns">
                            <div class="column is-6">
                                Scanned Amount:
                            </div>
                            <div class="column is-6 has-text-right">
                                <VeeInput
                                    placeholder="Books"
                                    type="currency" 
                                    v-model="invoice.scannedAmount"
                                    name="invoice.bookCount"
                                    :value="invoice.scannedAmount"
                                    />
                            </div>
                        </div>
                    </div>

                    <div class="column">
                        <b-notification 
                            type="is-info is-light"
                            :closable="isClosable" 
                            aria-close-label="Close notification">
                            Scan barcode like below 
                            ({{ storeState.countryCode }}-{{ storeState.code }})
                            {{ this.stateBarCodeFormat.Game }}/{{ this.stateBarCodeFormat.Book }}/{{ this.stateBarCodeFormat.Ticket }}
                            
                            <br> 
                            <img src="/images/lottery-barcode-sample.png">
                        </b-notification>
                                               
                    </div>


                </div>
                
            <footer class="modal-card-foot">
            <button class="button is-success" type="button" @click="submitForm" :disabled='isSaveDisabled' v-if="newTickets.length > 0">Save</button>
            <button class="button" type="button" @click="closeDialog">Cancel</button>
            <span class='is-danger'></span>
            </footer>   

                <div class="columns">
                    <div class="column box">
                        <b-table class="control"
                                :data='newTickets'
                                :paginated="isPaginated"
                                :per-page="perPage"
                                paginationPosition="both"
                                sortIcon="arrow-up"
                                sortIconSize="is-small"
                                default-sort=gameNumber
                                defaultSortDirection='asc'
                                >
                                <template>
                                <b-table-column class="is-2" field="gameNumber" label="Game" v-slot="props" sortable sorticon>
                                {{ props.row.gameNumber }}
                                </b-table-column>
                                <b-table-column class="is-2" field="bookNumber" label="Book" v-slot="props" sortable sorticon>
                                {{ props.row.bookNumber }}
                                </b-table-column>
                                <b-table-column class="is-2" field="bookNumber" label="Book" v-slot="props" sortable sorticon>
                                {{ props.row.name }}
                                </b-table-column>                                
                                <b-table-column numeric class="is-2" field="beginingSeq" label="Beg Seq" v-slot="props" sortable sorticon>
                                {{ props.row.ticketStartNum }}
                                </b-table-column>
                                <b-table-column numeric class="is-2" field="endingSeq" label="End Seq" v-slot="props" sortable sorticon>
                                {{ props.row.ticketEndNum }}
                                </b-table-column>                                                                
                                <b-table-column numeric class="is-2" field="bookNumber" label="Price" v-slot="props" sortable sorticon>
                                {{ props.row.pricePoint | formatCurrency }}
                                </b-table-column>
                                <b-table-column numeric class="is-2" field="bookNumber" label="Tickets" v-slot="props" sortable sorticon>
                                {{ (props.row.ticketEndNum + 1)  }}
                                </b-table-column> 
                                <b-table-column numeric class="is-2" field="bookNumber" label="Value" v-slot="props" sortable sorticon>
                                {{ props.row.pricePoint * (props.row.ticketEndNum + 1) | formatCurrency }}
                                </b-table-column>                                
                                </template>
                        </b-table>                                
                    </div>

                </div>

            </section>
            <lottery-game-form
                :isShowGameDialog="isShowGameDialog"
                :game="game"
                :states="states"
                :storeState="storeState"
                ref="gameForm"
                @toggle-game-dialog="toggleGameDialog"
                @save-game-record="saveGameRecord">                
            </lottery-game-form>

        </div>
        </b-modal>
        
    </div>
</div>

</template>


<script>

// import axios from 'axios'
import UserStore from "../../../../store/UserStore"

import axios from 'axios'
import LotteryGameForm from './LotteryGameForm'
import VeeInput from  "../../../../components/app/VeeInput.vue"
import Vue from 'vue'

export default {
    components: {
        VeeInput,
        LotteryGameForm
    },

    props: {
         canCancel: String, 
         isShowDialog: Boolean,
         storeId: Number,
         id: Number,
         action: String,
         newTickets: Array,
         invoice: Object,
         lotteryBookForm: Vue
    },

    directives: {
        focus: {
            // directive definition
            inserted: function (el) {
                el.focus()
            }
        }
    }, 

    data() {
        return {
            isSaveDisabled: false,
            isEditable: true,
            falseValue: false,
            isShowGameDialog: false,
            isMobileNativeDatePicker: false,
            isClosable: false,
            todayDate: "",
            book: {},
            game: {},
            stateGames: [],
            states: [],
            gameNumber: "",
            bookNumber: "",
            ticketCount: 0,
            soldTicket: 0,
            pricePoint: 0,
            beginningSeq: 0,
            endingSeq: 0,
            isPaginated: true,
            perPage: 10,
            isDisabled: false,
            scannedBookBarCode: "",
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'}),
            lotteryBarCodeFormats: UserStore.getters.getLotteryBarCodeFormats,
            stateBarCodeFormat: UserStore.getters.getStateBarCodeFormat,
            inputMethod: "Scan Book Bar Code",
            email: '',
            role: '',
            openOnFocus: true,
            keepFirst: true,
            clearable: false,
            date: new Date(),
            vendors: [],
            vendor: {},
            vendorName: '',
            name: '',
            page: 1,
            totalPages: 1,
            store: {},
            storeState: {},
            user: [],
            soundBookFoundOnSale: new Audio('/sounds/ScanBookFoundOnSale.mp3'),
            soundGameNotFound: new Audio('/sounds/ScanBookFoundInSafe.mp3'),
            soundBookNotFoundOnSaleOrInSafe: new Audio('/sounds/ScanBookNotFoundOnSaleOrInSafe.mp3'),
            soundBookEndingSequenceLess: new Audio('/sounds/ScanBookEndingSequenceLess.mp3'),
        }
    },

    methods: {
        
        inputMethodChanged() {
            // this.$refs.scannedBookBarCode.focus()
            if (this.inputMethod == "Scan Book Bar Code") {
                this.$refs.scannedBookBarCode.focus()
            }
            
        },

        processManualInput() {

            if (this.gameNumber != "" && this.isSaveDisabled !== "") {
                this.addBookToTable()
                this.gameNumber = ""
                this.bookNumber = ""
                this.$refs.gameNumber.focus()    
            }

        },

        async fetchStore() {
			if (  UserStore.getters.getActiveStore.storeId ) {
				axios.get('/sapi/stores/' + UserStore.getters.getActiveStore.storeId, {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken					},				
                    })
				.then( response => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    // this.store = JSON.parse(JSON.stringify(response.data.data))
                    this.store = response.data.data
                    // console.log("Stre",this.store)
                    this.getStoreState()
				})
				.catch( (err) => {
					if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
				})				
			}
        },

        fetchLotteryBook() {


            return new Promise((resolve, reject) => {
                if (this.user.activeStore && this.gameNumber != "" && this.bookNumber != "") {
                    var url = process.env.VUE_APP_API_ROOT_URL + "/lottery/book/?gameNumber=" + this.gameNumber + "&bookNumber=" + this.bookNumber
                     
                    axios.get(url,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                                'Refresh-Token': UserStore.getters.getRefreshToken
                            },                   
                        })
                        .then( (response) => {
                            if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                            this.game.lotteryBooks = response.data.data

                            if (response.data.data.gameId == this.game.id) {
                                reject("Lottery Book already in Inventory")
                            }

                            resolve()
                            
                        })
                        .catch( (error) => {
                            if (error) {
                                //
                            }
                            resolve()
                        })
                // } else {
                //     reject("Invalid Usage")
                }


            })

        },

        isBookExist() {

            return new Promise((resolve, reject) => {
                if (this.game.lotteryBooks.find(o => o.bookNumber === this.bookNumber 
                    && o.storeId === UserStore.getters.getActiveStore.storeId
                    )) {
                    reject("Lottery Book already in Inventory")
                } else {
                    resolve()
                }

            })

        },

        processScannedInput() {
            if (this.scannedBookBarCode != "") {

                this.scannedBookBarCode = this.scannedBookBarCode.replace('-', '')

                // var ticketDigitCount = this.stateBarCodeFormat.Game + this.stateBarCodeFormat.Book + 3

                // if (this.scannedBookBarCode.length !== ticketDigitCount ) {
                if (this.scannedBookBarCode.length < 13) {
                    this.soundBookEndingSequenceLess.play()
                    this.$buefy.toast.open({
                        message: "Invalid Scan " + this.scannedBookBarCode + '. Please try again.',
                                type: 'is-danger'
                    })
                    this.scannedBookBarCode = ""
                } else {
                    this.gameNumber = this.scannedBookBarCode.substring(0,this.stateBarCodeFormat.Game)
                    this.bookNumber = this.scannedBookBarCode.substring(this.stateBarCodeFormat.Game,this.stateBarCodeFormat.Game+this.stateBarCodeFormat.Book)

                    this.fetchLotteryGame()
                }

                this.$refs.scannedBookBarCode.focus()
            }

        },

        fetchLotteryGame() {
            if (this.user.activeStore && this.gameNumber != "") {
                var stateCode = (this.storeState.code ? this.storeState.code : UserStore.getters.getStore.state)
                var countryCode = (this.storeState.countryCode ? this.storeState.countryCode : UserStore.getters.getStore.country)
                var url = process.env.VUE_APP_API_ROOT_URL + "/lottery/games/"
                    + "?gameNumber=" + this.gameNumber
                    + "&stateCode=" + stateCode
                    + "&countryCode=" + countryCode
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.game = response.data.data

                        if (this.game.lotteryGame.stateId != this.storeState.id) {
                            this.gameNotFound()
                        }

                        if (this.game.lotteryGame.id) {
                            this.addBookToTable()
                            this.scannedBookBarCode = ""
                        }
                        

                    })
                    .catch( (error) => {
                        if (error.response && error.response.status === 400) {
                            this.gameNotFound()
                        }

                    })
            }
        },

        getStore() {
            // this.store = UserStore.getters.getStore
        },

        async getStoreState() {
            // if (this.storeState.code === undefined ) {
                const url = '/sapi/' + 'global/country/' + this.store.country + "/states/" + this.store.state
                // console.log("URL",url)
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data.data)

                        this.storeState = response.data.data
                        this.storeStateId = this.storeState.id
                        this.setBarCodeFormat()
                        this.fetchLotteryBook()

                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log("Failed to set Store State", err)
                    })
            // } else {
            //     this.storeState = UserStore.getters.getStoreState
            // }

        },
        
        gameNotFound() {
            this.soundGameNotFound.play()                            

            this.$buefy.toast.open({
                message: 'Instant Lottery Game ' + this.gameNumber + ' not found!',
                type: 'is-warning'
            })

            this.game = {
                'gameNumber': this.gameNumber,
                'name': "",
                'defaultSeqBeg': 0,  
                'defaultSeqEnd': 0,
                'ticketCount': 0,
                'pricePoint': 0.00,
                'totalBookValue': 0.00,
                'launchDate': this.todayDate,
                'stateId': this.storeState.id,
                'stateCode': this.storeState.code,
                'countryCode': this.storeState.countryCode,
                'stateName': this.storeState.name,
                'status': "RECEIVED"
            }
            this.isShowGameDialog = true
            this.$refs.gameForm.setFocusOnName()
        },

        toggleGameDialog() {
            this.isShowGameDialog = !this.isShowGameDialog
            // if (! this.isShowGameDialog) {
            //     this.scannedBookBarCode = ""
            // }
        },

        saveGameRecord() {
            this.toggleGameDialog()

            var url = process.env.VUE_APP_API_ROOT_URL + "/lottery/games"

            axios.post(url,
                this.game,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    this.$buefy.toast.open({
                        message: 'Saved Instant Lottery Game ' + this.game.gameNumber ,
                        type: 'is-success'
                    })
                    this.game = response.data.data
                    this.processScannedInput()
                    this.scannedBookBarCode = ""

                })
                .catch( (error) => {
                    if (error.response.status === 400) {
                        this.$buefy.toast.open({
                            message: 'Failed to save ' + this.game.gameNumber + error.message,
                            type: 'is-warning'
                        })
                    }
                })            
        },

        addBookToTable() {

            if (this.game.lotteryGame.id) {

                const alreadyScanned = this.newTickets.find(book => book.gameId === this.game.lotteryGame.id 
                                                                && book.bookNumber === this.bookNumber
                                                                // && book.storeId === UserStore.getters.getActiveStore.storeId
                                                                );

                if (alreadyScanned) {
                    this.soundBookEndingSequenceLess.play()
                    this.$buefy.toast.open({
                        message: 'Lottery Book Already Scanned - Game: ' + 
                                        this.game.lotteryGame.gameNumber + 
                                        " Book: " + this.bookNumber +
                                        " Price $" + this.game.lotteryGame.pricePoint.toFixed(2) ,
                                type: 'is-warning'
                    })

                    this.scannedBookBarCode = ""
                    return

                }
                
                this.isBookExist()
                    .then(() => {
                        this.soundBookFoundOnSale.play()
                        this.$buefy.toast.open({
                            message: 'Processing Scanned - Game: ' + 
                                            this.game.lotteryGame.gameNumber + 
                                            " Book: " + this.game.lotteryGame.name +
                                            " Price $" + this.game.lotteryGame.pricePoint.toFixed(2) ,
                                    type: 'is-success'
                        })

                        this.newTickets.push({
                            "gameId": this.game.lotteryGame.id,
                            "gameNumber": this.game.lotteryGame.gameNumber,
                            "name": this.game.lotteryGame.name, 
                            "bookNumber": this.bookNumber,
                            "ticketStartNum": this.game.lotteryGame.defaultSeqBeg,
                            "ticketEndNum": this.game.lotteryGame.defaultSeqEnd,                                          
                            "pricePoint": this.game.lotteryGame.pricePoint,
                            'launchDate': this.todayDate,
                            'ticketCount': this.game.lotteryGame.defaultSeqEnd - this.game.lotteryGame.defaultSeqBeg + 1,
                            'totalValue': (this.game.lotteryGame.defaultSeqEnd - this.game.lotteryGame.defaultSeqBeg + 1) * this.game.lotteryGame.pricePoint,
                            "receiveEmployeeId": 1,
                            "status": "RECEIVED",
                            // "createdDate": this.getMySQLTimeStamp(new Date()),
                            "receivedSalesId": 1
                            })
                        this.scannedBookBarCode = ""
                        this.invoice.scannedAmount += this.game.lotteryGame.pricePoint *
                                                        (this.game.lotteryGame.defaultSeqEnd - this.game.lotteryGame.defaultSeqBeg + 1)
                        this.invoice.scannedCount++
                    })
                    .catch((error) => {
                        this.soundBookEndingSequenceLess.play()
                        this.$buefy.toast.open({
                            message: error + ' - Game: ' + 
                                            this.game.lotteryGame.gameNumber + 
                                            " Book: " + this.bookNumber +
                                            " Price $" + this.game.lotteryGame.pricePoint.toFixed(2) ,
                                    type: 'is-danger'
                        })
                        this.scannedBookBarCode = ""

                    })

            }

        },

        getMySQLTimeStamp(value) {
            if (value) {
                if (typeof(value) == "string") {
                    value = new Date(value)
                }    
                return value.getFullYear() + '-' + 
                            String(value.getMonth() + 1).padStart(2, '0') + '-' + 
                            String(value.getDate()).padStart(2, '0') + 'T' +
                            String(value.getHours()).padStart(2, '0') + ':' +
                            String(value.getMinutes()).padStart(2, '0') + ':' +
                            String(value.getSeconds()).padStart(2, '0') + '.000'
            }             
        },

        bookScanNotAllowed(message) {
            this.soundBookNotFoundOnSaleOrInSafe.play()
            this.book = {}
            this.scannedBookBarCode = ""
            this.$buefy.dialog.alert({
                title: 'Book Not Allowed',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                type: 'is-danger',
                message: '<p class="has-text-weight-bold">Scanned: ' + this.gameNumber + '-' + this.bookNumber + "</p><br>" + message
            })
            // this.setFocusForScan()            
        },        

        closeDialog() {
          if (this.newTickets.length > 0)  {
            this.$buefy.dialog.confirm({
                title: 'Leaving without saving!',
                message: 'Are you sure you want to discard all changes and leave?',
                confirmText: 'Discard Changes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.scannedBookBarCode = ""
                    this.email = ""
                    this.role = ""                       
                    this.$emit('hide-dialog')
                }
            }) 
          } else {
            this.scannedBookBarCode = ""
            this.email = ""
            this.role = ""               
            this.$emit('hide-dialog')
           
          }

        },

        submitForm() {

            this.$emit('save-record')
            this.$emit('hide-dialog')

        },

        alertMessage(message) {
            alert(message)
        },

        setBarCodeFormat() {
            // var state = UserStore.getters.getStoreState
            this.stateBarCodeFormat = this.lotteryBarCodeFormats.find(item => item.stateCode === this.storeState.code && item.countryCode == this.storeState.countryCode)
            if (! this.stateBarCodeFormat) {
                this.stateBarCodeFormat = {"Game": 4, "Book": 7, "Ticket": 3}
            }
        },

    },

    computed: {

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

    },

    mounted() {
        this.user = this.getUser()
        this.fetchStore()
        var today = new Date()
        // this.getStoreState()
        // this.storeStateId = UserStore.getters.getStore.store 
        
        this.todayDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2)
    },

    updated: function() {
        if (this.$refs.scannedBookBarCode != undefined) {
            if(document.activeElement.name != 'invoice.bookCount' && 
                document.activeElement.name != 'invoice.amount' &&
                document.activeElement.name != 'invoice.number'
                ) {
                    this.$refs.scannedBookBarCode.focus()
                }
        }
    },


}
</script>

<style>

/* .scrollable {
    max-height: 200vw;
    width: 400px;
    overflow: auto; 
} */
.scanInput  input[type=text] {
    border: 2px solid red;
    border-radius: 4px;
    background: lightgoldenrodyellow;
}
</style>